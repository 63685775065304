import React from "react"
import { graphql, navigate } from "gatsby"
import PageRoot from "../components/PageRoot"
import { Card } from "antd"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"
import { StartExamButton } from "../components/Buttons"
import { getImageUrl } from "../utils"
import "./ExamPage.less"
import moment from "moment"

const ExamPage = ({ data }) => {
  const { t } = useTranslation()
  const certification = data.eventure.certifications_by_pk
  const { exam, id } = certification

  const certificationImage = getImageUrl(certification.landscape_locked)

  const duration = moment.duration(exam.duration).humanize() //(exam.duration / 1000 / 60).toFixed(0)
  const coolDown = moment.duration(exam.coolDown).humanize() //(exam.coolDown / 1000 / 60 / 60).toFixed(0)
  const count = exam.sampleCount

  return (
    <PageRoot title={exam.title}>
      <ContainerWithPadding size={"small"} align="center">
        <div className="exam-page">
          <h1>{exam.title}</h1>
          <p className="subtitle">{t("message:examMessage1")}</p>
          <div className="purchase-preview-wrapper">
            <Card
              style={{ width: "100%", marginBottom: 50 }}
              hoverable
              cover={<img alt={certification.title} src={certificationImage} />}
            >
              <Card.Meta
                title={certification.title}
                description={certification.short_description}
              />
            </Card>
          </div>
          <p className="description">{t("message:examMessage3")}</p>
          <p className="exam-warning">{t("message:examMessage4")}</p>
          <p className="exam-warning">{t("message:examMessage5")}</p>
          <p className="exam-warning">{t("message:examMessage6")}</p>
          <StartExamButton
            onClick={() => {
              navigate(`/online-tests/start-exam?id=${id}`)
            }}
          />
        </div>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query getCertificationWithExam($certificationId: eventure_uuid!) {
    eventure {
      certifications_by_pk(id: $certificationId) {
        ...PreviewCardCertification
        description
        seo {
          title
          description
          slug
        }
        wallpaper {
          ...Wallpaper
        }
        exam {
          title
          duration
          sampleCount
          coolDown
        }
      }
    }
  }
`

export default ExamPage
